import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "@mui/material";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import SupportInternal from "../modules/SupportInternal";
import CultureHero from "../modules/CultureHero";
import CareersTab from "../modules/CareersTab";
import PeopleCarousel from "../components/PeopleCarousel/PeopleCarousel";
import RPMmys from "../modules/RPMmys";
import WorkPlayHard from "../modules/WorkPlayHard";
import SocialCarousel from "../components/SocialCarousel/SocialCarousel";
import SocialFeed from "../modules/SocialFeed";

const peopleCarouselItems = [
  {
    image: "/eu-assets/video-1.webp",
    video: "ZqpTzuho1Ws",
  },
  {
    image: "/eu-assets/video-2.webp",
    video: "R7CMs0cvG20",
  },
  {
    image: "/eu-assets/video-3.webp",
    video: "ZLpGxfwZBK8",
  },
  {
    image: "/eu-assets/video-4.webp",
    video: "JlUh_1ee0fg",
  },
];

const socialCarouselItems = [
  {
    title: "Social Initiatives",
    heading: "Toys for Tots",
    details:
      "For the holidays, RPMers worked together to shatter another goal of providing toys to 100 children with the Toys for Tots Toy drive.",
    img: "/eu-assets/social-1.webp",
  },
  {
    title: "Social Initiatives",
    heading: "Gleaners Food Drive",
    details:
      "Teams of RPMers engaged in friendly competition for a great cause, collecting over 2,500 food items to help families in need.",
    img: "/eu-assets/social-2.webp",
  },
  {
    title: "Social Initiatives",
    heading: "Forgotten Harvest",
    details:
      "Our Interns came together to donate their time and help fundraise for an organization of their choice. Together their efforts raised over $1,100!",
    img: "/eu-assets/social-3.webp",
  },
];

const Culture = () => {
  return (
    <>
      <Helmet>
        <title>Company Culture | RPM</title>
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta
          name="description"
          content="Our people are our greatest asset. At RPM, we strive to build a culture where our employees love coming to work and are passionate about their career."
        />
        <meta property="og:title" content="Company Culture | RPM" />
        <meta
          property="og:description"
          content="Our people are our greatest asset. At RPM, we strive to build a culture where our employees love coming to work and are passionate about their career."
        />
      </Helmet>
      <CultureHero />
      <ModuleHeadline
        headline="Our people are our greatest assets"
        sx={{ my: 0, mt: { xs: 7, md: 9 }, mb: 4 }}
      />
      <SocialCarousel items={socialCarouselItems} />

      <WorkPlayHard />
      <Container sx={{ pt: { xs: 0, md: 6 } }}>
        <CareersTab />
      </Container>
      <PeopleCarousel items={peopleCarouselItems} />
      <RPMmys />
      <SocialFeed />

      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Culture;
