import React from "react";
import { Helmet } from "react-helmet";
import CarriersFeatureCards from "../modules/CarriersFeatureCards";
import MapNetwork from "../modules/MapNetwork";
import SupportInternal from "../modules/SupportInternal";
import Hub from "../modules/Hub";
import DriveApp from "../modules/DriveApp";
import TopHero from "../modules/TopHero";

const heroItems = ["/eu-assets/carriers-hero-6.webp"];

const Carriers = () => {
  return (
    <>
      <Helmet>
        <title>Carriers | RPM</title>
        <meta
          name="description"
          content="At RPM, we treat carriers as customers. We aim to reduce empty miles while keeping your truck full."
        />
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta property="og:title" content="Carriers | RPM" />
        <meta
          property="og:description"
          content="At RPM, we treat carriers as customers. We aim to reduce empty miles while keeping your truck full."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        taglineVariant="h2"
        tagline="Transport With Us"
        copy="Keeping your truck on the road and fully loaded is our top priority. We work with all equipment types and we are always looking to expand the network."
        ctaText="Join Our Network"
        ctaLink="/eu/contact"
        ctaTarget="_self"
      />
      <CarriersFeatureCards />
      <MapNetwork />
      <Hub />
      <DriveApp />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Carriers;
