import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

const images = [
  "/eu-assets/our-history.webp",
  "/eu-assets/our-history-2.webp",
  "/eu-assets/our-history-3.webp",
];

const OurHistory = () => {
  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container sx={{ flexDirection: { xs: "column", lg: "row" } }}>
        <Grid item xs={12} lg={8}>
          <Carousel
            swipe={false}
            indicators={false}
            navButtonsAlwaysInvisible={true}
            duration={2000}
            interval={6000}
          >
            {images.map((item, i) => (
              <Box
                component="img"
                src={item}
                alt={"Our history image " + i}
                key={i}
                sx={{
                  width: "100%",
                  maxWidth: "927px",
                }}
              />
            ))}
          </Carousel>
        </Grid>
        <Grid item xs={12} lg={4} sx={{ px: 2, pt: { xs: 2, lg: 8 } }}>
          <Typography variant="body2" component="p">
            Our History
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              mt: 4,
            }}
          >
            The road to our success
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 3 }}>
            Since our inception, RPM has consistently been recognized by the
            logistics industry and our peers as a top transportation provider in
            both finished vehicle logistics and specialized freight
            transportation.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurHistory;
