import { Outlet } from "react-router-dom";
import AppBar from "./modules/AppBar";
import SiteFooter from "./modules/SiteFooter";
import AboutRPM from "./pages/AboutRPM";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import Careers from "./pages/Careers";
import CarrierAgreement from "./pages/CarrierAgreement";
import Carriers from "./pages/Carriers";
import Culture from "./pages/Culture";
import Customers from "./pages/Customers";
import GeneralTerms from "./pages/GeneralTerms";
import Home from "./pages/Home";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";
import PageNotFound from "./pages/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Technology from "./pages/Technology";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";

function Root() {
  return (
    <>
      <AppBar />
      <Outlet />
      <SiteFooter />
    </>
  );
}

const routes = [
  {
    path: "/about-us",
    element: <AboutRPM />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:slug",
    element: <BlogPost />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/carrier-agreement",
    element: <CarrierAgreement />,
  },
  {
    path: "/carriers",
    element: <Carriers />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/culture",
    element: <Culture />,
  },
  {
    path: "/customers",
    element: <Customers />,
  },
  {
    path: "/general-terms",
    element: <GeneralTerms />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/news/:slug",
    element: <NewsArticle />,
  },
  {
    path: "/technology",
    element: <Technology />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export const routeConfig = [
  {
    path: "/",
    element: <Root />,
    errorElement: <PageNotFound />,
    children: routes,
  },
];
