import React from "react";
import { Helmet } from "react-helmet";
import HomeHero from "../modules/HomeHero";
import HomeFeatureCards from "../modules/HomeFeatureCards";
import AboutUs from "../modules/AboutUs";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import SupportHome from "../modules/SupportHome";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import StatsModule from "../components/StatsModule/StatsModule";
import OneRpm from "../modules/OneRpm";
import CustomerCarrierTabs from "../modules/CustomerCarrierTabs";
import HomeSolutions from "../modules/HomeSolutions";

const stats = [
  { label: "93%", footer: "9-Year CAGR" },
  { label: "60k+", footer: "Vehicles Transported Per Month" },
  { label: "30", footer: "Countries We Service" },
  { label: "20+", footer: "Languages We Speak" },
];

const Home = () => {
  return (
    <>
      <Helmet>
        <title>RPM | The Driving Force in Logistics</title>
        <meta
          name="keywords"
          content="vehicle transport, car transportation services, vehicle transportation services, car transportation, american auto transport, auto logistics"
        />
        <meta
          name="description"
          content="RPM is a complete, end-to-end transportation service provider. Vehicle or Freight, LTL or FTL, we support all transportation modes across all industries."
        />
        <meta
          property="og:title"
          content="RPM | The Driving Force in Logistics"
        />
        <meta
          property="og:description"
          content="RPM is a complete, end-to-end transportation service provider. Vehicle or Freight, LTL or FTL, we support all transportation modes across all industries."
        />
      </Helmet>
      <HomeHero
        tagline="We run toward complexity"
        copy="RPM is an international non-asset based logistics and supply chain solutions company focused on servicing the entire spectrum of the finished vehicle logistics landscape for Europe and North America including Mexico and Canada."
      />
      <ModuleHeadline headline="We Are Trusted by Thousands of Companies Across the Globe" />
      <HomeFeatureCards />
      <OneRpm />
      <StatsModule
        headline="We’re Solving The Industry’s Most Pressing Supply Chain Challenges"
        stats={stats}
      />
      <CustomerCarrierTabs />
      <HomeSolutions />
      <AboutUs />
      <AwardsRecognitions />
      <SupportHome />
    </>
  );
};

export default Home;
