import { Box, Container, Typography, Button } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import BulletList from "../components/BulletList/BulletList";
import { HeadingTwo, HeadingFour } from "../styles/UtilityStyles";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const CarrierAgreement = () => {
  return (
    <>
      <Helmet>
        <title>RPM | Carrier Agreement</title>
        <meta name="description" content="Terms & Conditions" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="md">
        <Box sx={{ py: 8, position: "relative" }}>
          <HeadingTwo>Broker - Carrier Agreement</HeadingTwo>
          <Button
            href="/eu-assets/Carrier_Agreement_211020.pdf"
            variant="outlined"
            target="_blank"
            endIcon={<SystemUpdateAltIcon />}
            sx={{
              position: { xs: "relative", md: "absolute" },
              right: 0,
              top: { xs: "6px", md: "66px" },
            }}
          >
            Download PDF Version
          </Button>
          <Typography variant="body1">
            <br />
            <br />
            This Broker Carrier Agreement (this “Agreement”), is made effective
            as of 20th day of September, 2021 (the “Effective Date”), by and
            between RPM LLC, located at 301 W. Fourth Street, Royal Oak MI
            48067, MC # 767565 (“Broker”), and xxxxxxx located in, Ashville, NY,
            a licensed motor carrier authorize
            <br />
            <br />
            WHEREAS, Broker arranges for the transportation of freight and
            products owned or controlled by its customers (individually and
            collectively, “Customers”), which includes the selection of a
            carrier and the payment of transportation charges; and
            <br />
            <br />
            WHEREAS, Broker desires that Carrier perform transportation of
            shipments that Broker has obtained under its arrangements with its
            Customers, subject to the terms and conditions of this Agreement.
            <br />
            <br />
            THEREFORE, for valid consideration received, the parties agree as
            follows:
          </Typography>

          <HeadingFour>General Business Covenants</HeadingFour>

          <Typography variant="body1">
            1. <u>Term</u>. The term of this Agreement shall commence on the
            Effective Date and shall continue thereafter from year to year until
            terminated by either party, with or without cause, on thirty (30)
            days prior written notice at any time.
            <br />
            <br />
            2. <u>Independent Contractor; Performance of Services</u>. Carrier
            understands and agrees that Carrier is an independent contractor of
            Broker and that Carrier has exclusive control and direction of the
            work Carrier performs pursuant to this Agreement and each Tender
            Document (defined below). Carrier represents that it is registered
            with Federal Motor Carrier Safety Association (“FMCSA”) as a motor
            carrier in interstate, intrastate, and/or foreign commerce and is in
            all respect qualified to transport freight as required by Broker.
            Carrier agrees to assume full responsibility for the payment of all
            local, state, federal and provincial payroll taxes, and
            contributions or taxes for unemployment insurance, workers’
            compensation insurance, pensions, and other social security or
            related protection with respect to the persons engaged by Carrier
            for Carrier’s performance of the transportation and related services
            for Broker or the Customer, and Carrier agrees to indemnify, defend
            and hold Broker and Customer(s) harmless therefrom. Carrier agrees
            to perform the services under this Agreement in accordance with the
            highest standards of the industry, operating under its own
            authority, using its own employees and using its own tractor unit.
            Carrier shall be wholly responsible for performing the contemplated
            transportation and for all costs and expenses of such
            transportation, including, without limitation, costs and expenses of
            all Carrier’s transportation equipment, its maintenance, and those
            persons who operate it. In providing services, Carrier represents
            and warrants that the driver(s) utilized are competent and properly
            licensed, and are fully informed of their responsibilities for the
            protection and care of the involved commodities.
            <br />
            <br />
            3. <u>Compliance with Applicable Laws and Regulations</u>. Carrier
            warrants that (1) it is legally qualified to perform the
            contemplated transportation, (2) it does not have a “conditional” or
            “unsatisfactory” FMCSA safety rating, (3) maintains all applicable
            statutory and regulatory required insurance, and (4) it shall
            immediately notify Broker in writing of any adverse change in its
            safety rating or any suspension or revocation of its operation
            authorities. Carrier agrees to comply with all applicable provisions
            of any international, federal, provincial, state and/or local law,
            rules and regulations. Without limiting the generality of the
            foregoing, Carrier agrees to comply with all laws, rules and
            regulations governing the sanitary transportation of human and
            animal food pursuant to the Food Safety Modernization Act, and
            further agrees to assume the obligations of a shipper as defined
            thereunder. Without limiting the foregoing, Carrier shall, at
            Carrier’s expense, comply with all laws, rules and regulations
            (including, without limitation, obtaining all permits and licenses)
            which are required for Carrier to provide Broker the transportation
            and related services under this Agreement. Carrier agrees not to
            accept a shipment from Broker if that shipment would require Carrier
            or any of its employees, agents or permitted subcontractors to
            exceed or violate any speed or safety law, rule or regulation.
            Carrier shall indemnify and hold Broker harmless from and against
            any and all liabilities, demands, claims, losses, costs, damages,
            and expenses (including attorneys' and other professional fees)
            arising from or relating to Carrier's non-compliance with this
            Section 3.
            <br />
            <br />
            4. <u>Solicitation of Broker’s Customers</u>. Carrier shall not
            during the term of this Agreement and for twelve (12) months
            following the termination of this Agreement, directly or indirectly,
            solicit the traffic of freight between the origins and destinations
            that were first offered to Carrier by Broker (“Brokered Traffic”).
            If Carrier transports Brokered Traffic in violation of this
            provision, then Broker shall be immediately entitled to a commission
            of fifty percent (50%) on the rates and charges received by Carrier
            on such Brokered Traffic
            <br />
            <br />
            5. <u>Successors and Assigns</u>. This agreement shall be binding
            upon the successors and permitted assigns of the parties hereto.
            Carrier shall not assign this Agreement, or any part thereof,
            without the prior written consent of Broker.
            <br />
            <br />
            6. <u>Venue and Choice of Law</u>. This Agreement will be governed
            by and construed in accordance with the laws of the State of
            Michigan, without regard to any conflict of laws or choice of law
            provisions. The parties hereby agree that any legal or equitable
            action or proceeding with respect to this Agreement shall be brought
            only in any court sitting in Oakland County of the State of
            Michigan, or the Eastern District Court of the United States sitting
            in Michigan, and each of the party’s hereby submits to and accepts
            generally and unconditionally the jurisdiction of those courts with
            respect to it and its property and irrevocably consents to the
            service of process in connection with any such action or proceeding
            by personal delivery or by the mailing thereof by registered or
            certified mail, postage prepaid to its address set forth below.
            <br />
            <br />
            7. <u>Modification</u>. No waiver, alteration or modification of any
            of the provisions of this Agreement, or any Tender Documents, shall
            be binding upon either party unless in writing and signed by the
            duly authorized representative of the party against whom such
            waiver, alteration or modification is sought to be enforced.
            <br />
            <br />
            8. <u>Savings Clause</u>. If any provision of this Agreement or any
            Tender Document is held to be invalid, the remainder of the
            Agreement or the Tender Document shall remain in full force and
            effect with the offensive term or condition being stricken to the
            extent necessary to comply with any conflicting law, rule or
            regulation. Carrier agrees that the termination, breach or
            invalidity of this Agreement or any Tender Document does not cause
            Carrier’s rules tariff (s), circular(s) or other publication(s) to
            govern the transportation and related services that Carrier provides
            to Broker or its Customers.
            <br />
            <br />
            9. <u>Waiver</u>. No provision of this Agreement or any Tender
            Document shall be waived by any party hereto, unless such waiver is
            written and signed by the authorized representative of the party
            against whom such waiver is sought to be enforced. Waiver by either
            party of any breach or failure to comply with any provision of this
            Agreement or any Tender Document by the other party shall not be
            construed as, or constitute, a continuing waiver of such provision,
            or a waiver of any other breach of or failure to comply with any
            other provision of this Agreement or any Tender Document.
            <br />
            <br />
            10. <u>Notices</u>. All notices required by or related to this
            Agreement shall be in writing and sent to the parties at the
            addresses set forth below and shall be deemed given when personally
            delivered, by confirmed facsimile, or three (3) days after having
            been mailed by certified mail, return receipt requested, to the
            following addresses:
            <br />
            <br />
            Notice Addresses:
            <br />
            <br />
            <strong>Broker</strong>
            <br />
            RPM
            <br />
            301 W. Fourth Street
            <br />
            Royal Oak, MI 48067
            <br />
            Fax: 248-414-1806
            <br />
            <br />
            <br />
            <strong>Carrier</strong>
            <br />
            xxx
            <br />
            <br />
            Either party may change the address to which future notices are to
            be addressed by giving written notice of such change to the other
            party.
            <br />
            <br />
            11. <u>Non-Exclusive Dealing</u>. This Agreement does not grant
            Carrier an exclusive right to perform the transportation and related
            services for Broker or its Customer(s). Broker does not guarantee
            any specific amount of shipments, tonnage, or revenue to Carrier.
            Broker is not restricted against tendering its freight to other
            carriers; Carrier is not restricted against performing
            transportation for other shippers.
            <br />
            <br />
            12. <u>Factoring</u>. Carrier shall provide Broker written notice of
            any assignment, factoring, or other transfer of its right to receive
            payment arising under this Agreement thirty (30) days prior to such
            assignment, factoring, or other transfer taking legal effect. Such
            written notice shall include the name and address of the
            assignee/transferee, date, date assignment is to begin, and terms of
            the assignment, and shall be considered delivered upon receipt of
            such written notice by Broker. Carrier shall be allowed to have only
            one assignment, factoring or transfer legally effective at any one
            point in time, and no multiple assignments, factoring or transfers
            by the Carrier shall be permitted. Carrier shall indemnify Broker
            against and hold Broker harmless from any and all lawsuits, claims,
            actions, damages (including reasonable attorney’s fees, obligation,
            liabilities and liens) arising or imposed in connection with the
            assignment or transfer of any account or right arising thereunder.
            Carrier also releases and waives any right, claim or action against
            Broker for amounts due and owing under this Agreement where Carrier
            has not complied with the notice requirements of this section.
            <br />
            <br />
            13. <u>Assignment of Rights to Payment by Carrier</u>. Any
            factoring, assignment, pledge, hypothecation or granting of a
            security interest in Carrier’s right to payment hereunder shall in
            no event modify, limit or terminate (i) the unlimited and unilateral
            rights of offset or recoupment provided to Broker hereunder or by
            law; or (ii) claims of Broker for offset, recoupment, loss or damage
            to any cargo or other property, including personal injury, or any
            other claim which Broker may have against Carrier for any reason.
            All of Broker’s claims and rights are specifically preserved and
            shall be superior to any rights or claims to payment of any
            assignee, factoror or creditor, regardless of any notice to Broker
            to the contrary. Carrier shall notify any such assignee, factoror or
            creditor of Broker’s rights in this regard.
            <br />
            <br />
            14. <u>Miscellaneous</u>. This Agreement may be executed by
            facsimile and in one or more counterparts and each of such
            counterparts shall, for all purposes, be deemed to be an original,
            but all such counterparts shall together constitute one and the same
            instrument. In the event either party incurs attorney’s fees, costs
            or expenses in enforcing any of the provision of this Agreement, or
            in exercising any right or remedy arising out of any breach of this
            Agreement by the other party or their agents, subcontractors or
            representatives, the prevailing party shall be entitled to an award
            of attorney’s fees, costs and expenses against the defaulting party.
          </Typography>

          <HeadingFour>Insurance & Liability</HeadingFour>
          <Typography variant="body1">
            15. <u>Insurance</u>. Carrier, at Carrier’s expense, shall maintain
            during the term of this Agreement commercial automobile liability
            insurance for the benefit of Broker and Customer, covering all
            vehicles however owned or used by Carrier to transport Broker’s
            shipments and property damage arising out of Carrier transportation
            under this Agreement, with minimum limits of not less than One
            Million Dollars ($1,000,000) per occurrence for personal injury
            (including death) and property damage, cargo liability insurance
            with minimum limits of not less than One Hundred Thousand Dollars
            ($100,000) per shipment, and if requested by Broker, commercial
            general liability insurance of One Million Dollars ($1,000,000) per
            occurrence. The Carrier shall also maintain any insurance coverages
            required by any government body including worker’s compensation (if
            applicable) for the types of transportation and related services
            specified. Carrier shall furnish written evidence of its insurance
            coverage to Broker upon request and shall advise Broker of any
            change in its insurance coverage thirty (30) days prior to the
            effective date of such change. Carrier further agrees to procure and
            maintain any and all insurance required by federal, state, local or,
            to the extent applicable, international laws. Carrier shall cause
            the required insurance to be procured naming Broker as an
            “additional insured” on any public liability, general liability
            and/or automobile liability policies. Upon request of Broker,
            Carrier shall furnish to Broker written certificates obtained from
            each insurance carrier showing that the required insurance has been
            procured. Carrier’s insurance will be deemed primary in the event of
            loss or damage. Carrier’s indemnification obligations described in
            this Agreement, including Section 17 below, will not be reduced or
            limited by the actual insurance policy limits that Carrier chooses
            to purchase. If Carrier fails to maintain such insurance, Broker may
            do so and charge Carrier for such cost and offset in accordance with
            this Agreement.
            <br />
            <br />
            16. <u>Cargo Liability</u>. Carrier assumes liability as a common
            carrier for loss, damage to or destruction of the goods entrusted to
            it or its permitted subcontractor’s care, custody or control and
            shall provide evidence of a BMC-32 Endorsement upon request. Carrier
            shall pay to Broker, or Broker may offset from the amounts Broker
            owes Carrier, for any losses arising from goods so lost, delayed,
            damaged or destroyed. Carrier shall not allow any of the goods
            tendered to Carrier to be sold or made available for sale or
            otherwise disposed of in any salvage markets, employee stores or any
            other secondary outlets without Broker’s prior written consent.
            Carrier agrees to either pay or settle all cargo claims within 60
            days of the receipt of a claim. Carrier agrees to notify Broker’s
            Claims Department in writing, immediately whenever an accident or
            potential claim occurs and provide Broker with any written reports,
            affidavits or other assistance necessary to assess the claim.
            <br />
            <br />
            17. <u>Indemnification</u>. Carrier aggress to indemnify, defend and
            hold Broker and its Customers, and their respective officers,
            directors, managers, members, shareholders, employees, agents and
            assigns, harmless from and against any and all fines, penalties,
            costs, demands, damages, (including bodily injury and property
            damage) losses, obligations, claims, liabilities and expenses
            (including reasonable attorney’s fees) of whatever type or nature
            arising out of or related to: (i) the maintenance, use or operation
            (including loading and unloading by Carrier, Carrier’s agents or
            contractors) of any motor vehicle or equipment in performance of
            services under this Agreement; (ii) any and all acts or omissions of
            Carrier, its agents, employees or contractors in providing the
            transportation services to be provided under this Agreement, (iii)
            an alleged violation by Carrier, as well as Carrier’s agents or
            contractors, of any federal, state, or municipal law, rule or
            regulation related to Carrier’s transportation services, and (iv)
            any use, operation, maintenance or possession of any owned or leased
            equipment by Carrier, Carrier’s agents or contractors. The
            obligations of Carrier under this Section shall survive termination
            of this Agreement.
          </Typography>

          <HeadingFour>Service</HeadingFour>
          <Typography variant="body1">
            18. <u>Performance of Services</u>. Carrier agrees to meet Broker’s
            distinct transit and pricing requirements agreed to by the parties
            from time to time as confirmed by the Broker’s issuance of a Tender
            Sheet, Pre-note, Rate Confirmation, Bill of Lading, Proof of
            Delivery or other load tender document that Broker may use from time
            to time (in each case, a “Tender Document”). Additional service
            requirements of Carrier are as follows:
          </Typography>
          <BulletList
            items={[
              "Carrier shall transport Broker’s shipments without delay. Carrier shall immediately notify Broker of any likelihood of delay. Carrier shall transport all freight tendered by Broker only on equipment operated under Carrier’s authority.",
              "Carrier shall comply with all of Broker’s reasonable shipping instructions communicated to Carrier, and to comply with all applicable provisions of any provincial, federal, state and/or local law or ordinance and all lawful orders, rules and regulations issued thereunder.",
              "Carrier shall obtain from the consignee a complete, signed delivery receipt for each shipment, and it shall notify Broker immediately of any exception on any document. Carrier shall send Broker delivery receipts and bills of lading within twenty-four (24) hours of delivery, as Broker directs.",
              "If Broker requests Carrier to transport any shipment required to be placarded under DOT rules as a hazardous material, the additional provisions in Appendix A, including additional insurance requirements, shall apply for each such shipment.",
              "Documents for each of Broker’s shipments shall name Broker as “broker” and Carrier as “carrier”. If there is a wrongly worded document, the parties will treat it as if it showed Broker as “broker” and Carrier as “carrier”. If there is a conflict between this Agreement and any transportation document related to Broker’s shipment, this Agreement shall govern.",
              "Carrier is responsible for ensuring that all freight is properly blocked and braced for transportation to allow for the safe and damage-free delivery of the goods and to avoid damage to other property.",
              "Carrier shall be responsible for ensuring compliance with all customs and security laws that are applicable to the transportation services either domestically in the United States, Canada or Mexico if within the scope of the Tender Document or for import or export to or from the United States, Canada or Mexico.",
              "Carrier is responsible to determine that the goods being shipped are in apparent good order and condition, to the extent that such is ascertainable through a visual examination of the exterior of the goods shipped, before loading and, in the event that they are not, Carrier will contact Broker for further instructions.",
            ]}
          ></BulletList>

          <Typography variant="body1">
            19. <u>Bills of Lading and Delivery Receipts</u>. Carrier will issue
            and sign a standard, uniform straight bill of lading or other
            receipt acceptable to Broker and Customer, upon acceptance of goods
            for transportation. All terms or conditions written or printed on
            the receipts or bills of lading which have not been specifically
            agreed to by Broker, either in this Agreement or on any signed
            addenda hereto, shall have no binding effect against Broker. The
            receipt of a bill of lading issued or executed by Carrier shall be
            prima-facie evidence of receipt of goods in good order and condition
            by Carrier unless otherwise noted on the face of said document.
            Carrier shall submit to Broker the original signed bill of lading
            evidencing good delivery of the goods, unless otherwise specifically
            agreed by Broker; and in that case, Carrier shall maintain custody
            of the original signed bills of lading and will provide them to
            Broker upon request. If Carrier fails to maintain and provide the
            bills of lading as agreed, Carrier assumes all risk of loss
            resulting from the failure to prove good delivery. In the event that
            Carrier’s personnel are not allowed or afforded an opportunity to
            view and/or examine the goods in order to ascertain the condition of
            those goods prior to loading on to Carrier’s vehicle, Carrier’s
            personnel shall immediately notify Broker and await instructions
            prior to transporting the shipment, and shall note on the bill of
            lading that they were not allowed or afforded an opportunity to view
            and/or examine the goods shipped.
          </Typography>

          <HeadingFour>Business Conduct</HeadingFour>
          <Typography variant="body1">
            20. <u>Rates and Charges</u>. The rates and charges that shall be in
            effect for all movements tendered to Carrier by Broker shall be as
            set forth on the Tender Document issued by the Broker and given to
            Carrier (the “Charges”). Carrier represents, warrants and agrees
            that there are no other applicable rates or charges except those set
            forth on the Tender Document issued by Broker from time to time.
            Carrier agrees that it shall not bill the shipper/consignee or any
            third party directly nor shall it communicate in any manner,
            directly or indirectly, with Broker’s Customers, consignors,
            consignees or any party other than Broker, concerning the collection
            of any of the Charges relating to transportation services accruing
            in connection with or as a consequence of this Agreement; and waives
            any right it may otherwise have to proceed or commence any action
            against any such party for the collection of any freight bills
            arising out of transportation services performed by Carrier under
            this Agreement. Carrier will not accept payment from any
            shipper/consignee or any third party for providing transportation
            under this agreement. Carrier’s performance of the work contained in
            the Tender Document, or commencement to perform, is confirmation of
            acceptance of the rate and the Charges contained in that document.
            No other rates, terms, conditions, charges, fees, expenses or any
            other amounts shall be payable by Broker to Carrier unless such
            items are included in the written Tender Document. Nothing in any
            Bill of Lading or other Carrier issued document shall be binding
            upon Broker. Unless specifically included in the Tender Document,
            Broker shall not be required to make payment for any accessorial
            charges, equipment or escort rentals for the tendered movement, or
            any other special permitting or operating expenses.
            <br />
            <br />
            21. <u>Carrier’s Right to Subcontract</u>. Except as provided in
            this section, Carrier shall not, in any manner, sub-contract, broker
            or tender to any third party for transportation, any freight
            tendered to Carrier by Broker for transportation pursuant to this
            Agreement. Carrier may subcontract the services that Carrier has
            agreed to perform for Broker under this Agreement, only if: (i)
            Carrier provides Broker prior written notice of such subcontracting,
            (ii) Broker acknowledges in writing, that the subcontracting may
            occur; and (iii) Carrier remains liable for the full and faithful
            performance of all obligations contained in this Agreement,
            including the obligation to indemnify Broker and Customer in
            accordance with Section 17, as if no such subcontracting has taken
            place. Nothing in this Agreement shall permit or allow Carrier to
            assign or delegate any of its other duties or obligations under this
            Agreement.
            <br />
            <br />
            22. <u>Payment</u>. On a monthly basis, Carrier will issue Broker an
            invoice for any amounts due and owing by Broker pursuant to this
            Agreement. Payment of all undisputed invoiced amounts shall be made
            by Broker within thirty (30) days from the date of the invoice.
            <br />
            <br />
            23. <u>Waiver of Carrier’s Lien</u>. Carrier, for itself and on
            behalf of all of its agents or subcontractors, hereby waives and
            agrees not to assert or make any claim arising out of any motor
            carrier’s lien which Carrier would otherwise be allowed to assert
            upon any cargo or goods which are tendered by Broker to Carrier or
            which are otherwise in the possession or control of Carrier or its
            subcontractors or agents. Carrier shall not withhold delivery of any
            freight due to any dispute with Broker or Customer.
            <br />
            <br />
            24. <u>Insolvency</u>. In the event of insolvency proceedings being
            instituted by or against Carrier, then Broker, any agent of Broker,
            or Broker’s Customer may immediately enter upon any owned or leased
            property of Carrier where any cargo, goods or Customer owned or
            leased equipment may be found and take possession of such goods or
            owned or leased equipment without notice or liability to Carrier.
            <br />
            <br />
            25. <u>Set-Off and Recoupment</u>. Broker may set off, withhold,
            recover or recoup any amounts payable to Carrier hereunder or under
            any other agreement or arrangement between Carrier and Broker, or
            any of its affiliates, subsidiaries against. In addition, in the
            event Broker makes payment to Carrier as provided herein and Carrier
            fails to make payment to any applicable subcontractor, then Broker
            may, at its option and sole discretion, pay such subcontractor and
            offset the amount paid against any amounts owed or to be owing to
            Carrier by Broker. Failure to comply with any DOT, FMCSA policy, law
            or regulation, or any breach of any element of this Agreement,
            howsoever minor, may result in non-payment for freight charges
            and/or set off and recoupment claims.
            <br />
            <br />
            26. <u>Hostage Loads</u>. For purposes of this Agreement “hostage
            loads” is defined as the refusal by Carrier to immediately release
            shipper’s freight upon demand or otherwise exerting unauthorized
            control over freight, refusal to deliver a load at the scheduled
            time and place of delivery, refusing to provide Broker with
            information on the location of shipper’s freight or refusing to
            deliver, or failure to release or produce such freight. In the event
            that Carrier does hold freight hostage, Carrier agrees to pay Broker
            $5,000.00 for each day that freight is held. Carrier agrees that
            such amount constitutes reasonable liquidated damages for damage to
            Broker’s reputation with the shipper and/or others. Broker shall be
            entitled to all other available or alternative remedies at law or
            equity in the case of hostage loads.
            <br />
            <br />
            27. <u>Contract Carriage</u>. All services performed by Carrier
            pursuant to this Agreement shall be as a motor carrier of property
            in interstate or foreign commerce and shall be rendered as contract
            carriage within the meaning of 49 U.S.C. §§ 13102(4)(B) and
            14101(b). Broker and Carrier hereby expressly waive all provisions
            of Chapters 137 and 147 and any other provisions of Subtitle IV,
            Part B of Title 49, United States Code, to the extent that such
            provisions are in conflict with express provisions of this
            Agreement. The parties do not, however, waive the provisions of that
            subtitle relating to registration, insurance, or safety fitness.
            <br />
            <br />
            28. <u>Entire Agreement</u>. This Agreement, together with the
            Tender Documents issued by Broker constitutes the entire agreement
            between the parties and supersedes all other oral and written
            communications, agreements and contracts between Carrier and Broker
            with respect to the subject matter of this Agreement. Any provisions
            on any Carrier-provided form whether before or after the execution
            of this Agreement, shall be deemed null and void and of no effect
            and shall not modify the terms and conditions of this Agreement.
            <br />
            <br />
            IN WITNESS WHEREOF, the undersigned individuals have executed this
            Agreement as of the Effective Date, and by doing so, represent and
            warrant that they have been or are specifically authorized to do so
            on behalf of the organization they represent.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default CarrierAgreement;
