import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography } from "@mui/material";
import SolutionsAccordion from "../components/SolutionsAccordion/SolutionsAccordion";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomeSolutions = () => {
  const items = [
    {
      label: "Quality and Support",
      body: "We are hyper focused on providing our customers with service-oriented solutions.",
      img: "/eu-assets/quality-support.webp",
    },
    {
      label: "Agility",
      body: "Our non-asset based model allows us to scale to our customers' needs and meet demand, immediately.",
      img: "/eu-assets/agility-eu-4.webp",
    },

    {
      label: "Access",
      body: "We help drivers find the most optimal loads so they can limit empty miles and thus reduce their carbon footprint. Shippers can tap into this connected capacity network to drive cost savings within their supply chain, as well as higher service levels for their customers.",
      img: "/eu-assets/access-eu-2.webp",
    },
    {
      label: "24/7 Support",
      body: "24/7/365, regardless of the time zone or country, our team is in constant communication with customers and carriers to ensure we always provide quality and service that meets and exceeds expectations.",
      img: "/eu-assets/247-support.webp",
    },
    {
      label: "Relationships",
      body: "Growing for over a decade, RPM leverages a global carrier network that fits the needs of our customers.",
      img: "/eu-assets/relationships-eu.webp",
    },
  ];

  const theme = useTheme();
  return (
    <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
      <Box sx={{ p: 4, my: 5 }}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Typography variant="body2" component="p">
              RPM Solutions
            </Typography>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                my: 4,
              }}
            >
              We Are a Disruptive and Differentiated, End-to-End Transportation
              Service Provider.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={6}>
            <SolutionsAccordion items={items} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HomeSolutions;
