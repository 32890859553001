import React from "react";
import { Helmet } from "react-helmet";
import SupportInternal from "../modules/SupportInternal";
import ModuleHeadline from "../components/ModuleHeadline/ModuleHeadline";
import Carousel from "../components/Carousel/Carousel";
import VehicleSpecializedSolutions from "../modules/VehicleSpecializedSolutions";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import TopHero from "../modules/TopHero";

const carouselItems = [
  {
    title: "Non-Asset Based Provider",
    heading: "We’re Not Your Typical Broker",
    details:
      "The combination of our experience, our network, and our proprietary technology allows us the ability to solve our vehicle shippers’ complex challenges. We build capacity networks around our customers’ complex needs.",
  },
  {
    title: "Real-time Updates",
    heading: "Transparency & Visibility",
    details:
      "Our proprietary technology provides full shipment visibility into every load, allowing customers to see exactly where a vehicle is in transit.",
  },
  {
    title: "Carrier Compliance",
    heading: "Delivering Quality",
    details:
      "We build quality with our network, thus minimizing risk, reducing damage, and ultimately ensuring the right driver is handling the right load, every time.",
  },
  {
    title: "OEM Quality",
    heading: "Process & Procedures",
    details:
      "RPM has developed a proprietary process to identify carriers in the network who can support OEMs expectations around quality.",
  },
  {
    title: "Non-traditional Capacity",
    heading: "Specialized Solutions",
    details:
      "Dedicated enclosed vehicle transport for events, charter, larger vehicles and more.",
  },
];

const heroItems = ["/eu-assets/customers-hero-8.webp"];

const Customers = () => {
  return (
    <>
      <Helmet>
        <title>
          RPM Customers | Work With Professional Auto Transportation Specialists
        </title>
        <meta
          name="description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
        <meta
          property="og:title"
          content="RPM Customers | Work With Professional Auto Transportation Specialists"
        />
        <meta
          property="og:description"
          content="RPM gives their customers refreshing clarity on car shipping cost and car transport services. Click here to find out how much to ship a car near you."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        tagline="We Move Hundreds of Thousands of Vehicles Per Year"
        taglineVariant="h2"
        copy="Vehicle Transportation is a complex world and we have a scalable network that allows us to deliver unique solutions for our customers' ever changing needs."
        ctaText="Let’s Talk"
        ctaLink="/eu/contact"
        ctaTarget="_self"
      />
      <ModuleHeadline headline="WE’RE TRUSTED BY THOUSANDS OF COMPANIES ACROSS THE GLOBE" />
      <Carousel items={carouselItems} />
      <VehicleSpecializedSolutions />
      <AwardsRecognitions />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
};

export default Customers;
