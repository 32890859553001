import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const TechnologyFirst = () => {
  const theme = useTheme();
  return (
    <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
      <Box
        sx={{
          display: "flex",
          padding: { xs: "56px 0", lg: "72px 0" },
          alignItems: { xs: "center", lg: "top" },
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          component="img"
          src="/eu-assets/technology-first.webp"
          alt="map"
          sx={{
            width: "100%",
            maxWidth: "753px",
            height: "auto",
          }}
        />
        <Box sx={{ maxWidth: "424px", py: 5, px: 2 }}>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
            }}
          >
            We Put Technology First
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 3 }}>
            Our custom technology stack allows us to optimize our opportunities
            and maximize our efficiencies, leading to the highest level of
            customer satisfaction and equipment utilization. Our system is a
            modern, cloud-based micro-services enabled solution, employing the
            most modern technological offerings to deliver the optimum business
            performance.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TechnologyFirst;
