import React from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MapNetwork = () => {
  const theme = useTheme();
  return (
    <Container
      disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
      maxWidth="xl"
    >
      <Box
        sx={{
          display: "flex",
          px: 2,
          py: { xs: 6, lg: 8 },
          alignItems: { xs: "center", lg: "center" },
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          component="img"
          src="/eu-assets/eu-map-2.webp"
          alt="map"
          sx={{
            width: "100%",
            maxWidth: "918px",
            height: "auto",
            mb: 5,
          }}
        />
        <Box>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
            }}
          >
            Daily shipments across Europe
          </Typography>
          <Typography variant="body1" component="p" sx={{ my: 3 }}>
            RPM is a complete, end-to-end transportation service provider. As a
            logistics operator, we serve the world's leading car manufacturers,
            and being part of their supply chain we can offer cargo connections
            in every part of Europe. Find out more about our cargo route network
            in Europe.
          </Typography>
          <Button
            variant="contained"
            disableElevation
            href="/eu/contact"
            target="_self"
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MapNetwork;
