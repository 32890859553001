import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routeConfig } from "./Router";

const router = createBrowserRouter(routeConfig, { basename: "/eu" });

function App() {
  return <RouterProvider router={router} />;
}

export default App;
