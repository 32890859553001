import React from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Fade from "react-reveal/Fade";
import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-material-ui-carousel";

const HeroBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "auto",
    padding: "0",
    backgroundImage: "none",
  },
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "345px",
  },
}));

const HeroBoxBottom = styled(Box)(({ theme }) => ({
  padding: "64px 0 0",
  maxWidth: "380px",
  [theme.breakpoints.down("md")]: {
    backgroundImage: `url(${"/eu-assets/top-hero-text-mb.webp"})`,
    backgroundSize: "cover",
    maxWidth: "100%",
    padding: "56px 25px",
  },
}));

const TopHero = (props) => {
  const theme = useTheme();

  const heroItems = props.heroItems;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          height: 534,
          backgroundImage: `url(${"/eu-assets/top-hero-sail-left.webp"})`,
          ...(props.direction === "right" && {
            backgroundImage: `url(${"/eu-assets/top-hero-sail-right.webp"})`,
          }),
          backgroundSize: "auto 534px",
          backgroundRepeat: "no-repeat",
          position: "relative",
          backgroundPosition: "50% 0",
          overflowX: "hidden",
          [theme.breakpoints.down("md")]: {
            height: "auto",
            padding: "0",
            backgroundImage: "none",
          },
        }}
      >
        <Carousel
          swipe={false}
          indicators={false}
          duration={2000}
          interval={6000}
          sx={{
            position: "absolute",
            zIndex: "-1",
            right: "50%",
            transform: "translateX(50%)",
            height: 534,
            width: 2110,
            top: 0,
            [theme.breakpoints.down("md")]: {
              top: "-25px",
              right: "40%",
              height: "375px",
            },
          }}
        >
          {heroItems.map((item, i) => (
            <Box
              component="img"
              src={item}
              alt={"Hero image " + i}
              key={i}
              sx={{
                height: 534,
                [theme.breakpoints.down("md")]: {
                  height: 390,
                },
              }}
            />
          ))}
        </Carousel>
        <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
          <HeroBox>
            <HeroBoxTop></HeroBoxTop>
            <HeroBoxBottom>
              <Fade left>
                <Typography
                  variant={props.taglineVariant ? props.taglineVariant : "h1"}
                  component="h1"
                  sx={{
                    color: "#fff",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontWeight: 700,
                  }}
                >
                  {props.tagline}
                </Typography>
              </Fade>
              <Fade bottom>
                <Typography
                  variant="body1"
                  component="p"
                  color="white"
                  sx={{
                    py: 3,
                  }}
                >
                  {props.copy}
                </Typography>
              </Fade>
              {props.ctaLink && props.ctaTarget && props.ctaText && (
                <Button
                  variant="outlined"
                  href={props.ctaLink}
                  target={props.ctaTarget}
                >
                  {props.ctaText}
                </Button>
              )}
            </HeroBoxBottom>
          </HeroBox>
        </Container>
      </Box>
    </Box>
  );
};

export default TopHero;
