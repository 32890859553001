import { Box, Button, Container, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import OurLocations from "../modules/OurLocations";
import { HeadingTwo } from "../styles/UtilityStyles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { departments } from "../contactOptions";
import TextField from "@mui/material/TextField";
import emailjs from "@emailjs/browser";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #111827",
  boxShadow: 24,
  p: 4,
};

const Contact = () => {
  const [company, setCompany] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailTo, setEmailTo] = React.useState();
  const [ext, setExt] = React.useState("");
  const [mcNumber, setMCNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [service, setService] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [termsAgree, setTermsAgree] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleTermsAgree = () => {
    setTermsAgree(!termsAgree);
  };

  const handleDepartmentSelect = (event) => {
    setDepartment(event.target.value);
    const index = departments
      .map((item) => item.value)
      .indexOf(event.target.value);
    setServices(departments[index].services);
  };

  const handleServiceSelect = (event) => {
    setService(event.target.value);
    const index = services
      .map((item) => item.value)
      .indexOf(event.target.value);
    setEmailTo(services[index].email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .send(
        "service_wzkjesk",
        "template_0munazn",
        {
          department: department,
          service: service,
          name: name,
          email: email,
          company: company,
          mcNumber: mcNumber,
          phone: phone,
          ext: ext,
          message: message,
          emailTo: emailTo,
          termsAgree: termsAgree,
        },
        "Wgraqm1sdrUdTBj9A"
      )
      .then(
        (result) => {
          handleOpenModal();
        },
        (error) => {
          console.log(error.text);
        }
      );

    handleClose();
  };

  const handleClose = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setService("");
    setDepartment("");
    setCompany("");
    setCompany("");
    setName("");
    setEmail("");
    setPhone("");
    setExt("");
    setMessage("");
    setMCNumber("");
    setTermsAgree(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <Helmet>
        <title>RPM | Contact Us</title>
        <meta name="description" content="Contact Us" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ py: 8, position: "relative" }}>
          <HeadingTwo>Contact Us</HeadingTwo>
          <Typography variant="body1" sx={{ py: 2 }}>
            Please feel free to contact us regarding any concerns or inquiries.
          </Typography>
          <Typography variant="body5" sx={{ py: 2, display: "block", mb: 1 }}>
            * Required fields
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                required
                id="outlined-select-currency"
                select
                defaultValue=""
                label="Department"
                value={department}
                onChange={handleDepartmentSelect}
                helperText="Select the department you would like to speak with"
              >
                {departments.map((department, index) => (
                  <MenuItem value={department.value} key={index}>
                    {department.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                required
                id="outlined-select-currency"
                select
                label="Service"
                value={service}
                onChange={handleServiceSelect}
                helperText="Select the service you are inquiring about"
              >
                {services.map((service, index) => (
                  <MenuItem value={service.value} key={index}>
                    {service.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                required
                id="outlined-required"
                label="Name"
                type="text"
                value={name}
                onInput={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                required
                id="outlined-required"
                label="Email"
                type="email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="outlined-required"
                label="Company Name"
                type="text"
                value={company}
                onInput={(e) => setCompany(e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="outlined-required"
                label="MC# (If Applicable)"
                type="text"
                value={mcNumber}
                helperText="If you are a carrier, please fill in your MC number"
                onInput={(e) => setMCNumber(e.target.value)}
              />
            </FormControl>

            <FormControl sx={{ my: 2, width: { xs: "100%", sm: "62%" } }}>
              <TextField
                required
                type="tel"
                id="outlined-required"
                label="Phone number"
                value={phone}
                onInput={(e) => setPhone(e.target.value)}
              />
            </FormControl>

            <FormControl
              sx={{
                my: 2,
                ml: { xs: "0", sm: "8%" },
                width: { xs: "100%", sm: "30%" },
              }}
            >
              <TextField
                id="outlined-ext"
                label="Ext."
                value={ext}
                type="tel"
                onInput={(e) => setExt(e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="outlined-message"
                label="Message"
                value={message}
                placeholder="Tell us how we can help you..."
                required
                multiline
                type="text"
                rows={4}
                onInput={(e) => setMessage(e.target.value)}
              />
            </FormControl>
            <Box sx={{ my: 3, display: "flex", alignItems: "center" }}>
              <Checkbox
                {...label}
                required
                id="required"
                color="secondary"
                checked={termsAgree}
                onChange={handleTermsAgree}
              />
              <label htmlFor="required">
                <Typography variant="body5">
                  I have read and agreed to both the&nbsp;
                  <Link href="/terms" target="_blank">
                    terms & conditions
                  </Link>
                  &nbsp;and the&nbsp;
                  <Link href="/privacy" target="_blank">
                    Privacy Policy
                  </Link>
                </Typography>
              </label>
            </Box>
            <Button type="submit" variant="contained" disableElevation>
              Submit
            </Button>
          </form>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Thank you!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Thak you for taking the time to send us a message. A member of our
              team will reach out to you shortly if neccessary.
            </Typography>
          </Box>
        </Modal>
      </Container>
      <Container>
        <OurLocations />
      </Container>
    </>
  );
};

export default Contact;
