import React from "react";
import { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Divider,
  Collapse,
  Button,
} from "@mui/material";
import WorkPlayCard from "../components/WorkPlayCard/WorkPlayCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const cards = [
  {
    title: "Office chili cook off",
    img: "/eu-assets/work-play-1.webp",
  },
  {
    title: "Fowling",
    img: "/eu-assets/work-play-fowling.webp",
  },
  {
    title: "Tiger’s game",
    img: "/eu-assets/work-play-3.webp",
  },
  {
    title: "Game night",
    img: "/eu-assets/work-play-4.webp",
  },
  {
    title: "Office olympics",
    img: "/eu-assets/work-play-5.webp",
  },
  {
    title: "Weekend volleyball",
    img: "/eu-assets/work-play-6.webp",
  },
];

const WorkPlayHard = () => {
  const [showMore, setShowMore] = useState(false);
  const handleChange = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <Container sx={{ my: 4 }}>
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
          mb: 2,
        }}
      >
        Working Hard, Playing Harder
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {cards.slice(0, 3).map((card, i) => (
          <WorkPlayCard key={i} title={card.title} img={card.img} />
        ))}
      </Box>
      {cards.length > 3 && (
        <>
          <Collapse in={showMore}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {cards.slice(3).map((card, i) => (
                <WorkPlayCard key={i} title={card.title} img={card.img} />
              ))}
            </Box>
          </Collapse>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 9,
            }}
          >
            <Divider sx={{ width: { xs: "90%", sm: "50%" } }}>
              <Button
                variant="text"
                onClick={handleChange}
                endIcon={
                  showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            </Divider>
          </Box>
        </>
      )}
    </Container>
  );
};

export default WorkPlayHard;
