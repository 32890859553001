import React from "react";
import { Helmet } from "react-helmet";
import { Container, Box, Typography } from "@mui/material";
import Testimonials from "../components/Testimonials/Testimonials";
import SupportInternal from "../modules/SupportInternal";
import BulletList from "../components/BulletList/BulletList";
import CultureTab from "../modules/CultureTab";
import IndustryNewbie from "../modules/IndustryNewbie";
import JobPositions from "../modules/JobPositions";
import AwardsRecognitions from "../modules/AwardsRecognitions";
import TopHero from "../modules/TopHero";

const testimonials = [
  {
    description: `“We love to get things done fast. That’s why we love working with RPM.”`,
    author: "Loki Williams Bright",
    authorTitle: "Purchasing & Logistics Manager",
  },
  {
    description: `“Shipping with RPM was seamless, everything arrived perfect and on-time. I will definitely use them for future transport needs.”`,
    author: "Landon M.",
    authorTitle: "Supply Chain & Logistics Manager",
  },
  {
    description: `“RPM really understands my transportation needs and are able to be flexible. They have yet to say “no”, regardless of how complex my request.”`,
    author: "Jeremiah D.",
    authorTitle: "Logistics Coordinator",
  },
];

const heroItems = [
  "/eu-assets/careers-hero-slide-1.webp",
  "/eu-assets/careers-hero-slide-2.webp",
  "/eu-assets/careers-hero-slide-3.webp",
  "/eu-assets/careers-hero-slide-4.webp",
];

export default function Careers() {
  return (
    <>
      <Helmet>
        <title>Start a Career at RPM</title>
        <meta
          name="description"
          content="Join our growing organization in a position that will challenge, grow, and cultivate your skills into a future leader."
        />
        <meta property="og:title" content="Start a Career at RPM" />
        <meta
          property="og:description"
          content="Join our growing organization in a position that will challenge, grow, and cultivate your skills into a future leader."
        />
      </Helmet>
      <TopHero
        direction="left"
        heroItems={heroItems}
        tagline="Starting Your Career with RPM"
        copy="Join our growing organization in a position that will challenge, grow
        and cultivate your skills into a future leader."
      />
      <AwardsRecognitions />
      <JobPositions />

      <Container maxWidth="lg" disableGutters>
        <Box
          sx={{
            display: "flex",
            padding: { xs: 0, md: "36px 0" },
            alignItems: { xs: "center", lg: "top" },
            justifyContent: "center",
            flexDirection: { xs: "column-reverse", lg: "row" },
            mb: { xs: 0, md: 3 },
          }}
        >
          <Box
            component="img"
            src="/eu-assets/rpm-benefits.webp"
            alt="map"
            sx={{
              width: "100%",
              maxWidth: "749px",
              height: "auto",
            }}
          />
          <Box sx={{ maxWidth: { xs: "100%", lg: "434px" }, py: 5, px: 2 }}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
              }}
            >
              RPM Benefits
            </Typography>
            <Typography variant="body1" sx={{ pt: 3 }}>
              Starting your career at RPM, we want to support you in the office
              and at home. We offer competitive benefits to encourage a flexible
              and sustainable career.
            </Typography>
            <BulletList
              items={[
                "Unlimited PTO",
                "Employer Match 401k",
                "Employer Paid Life Insurance, Short- & Long-Term Disability",
                "Hybrid Work Schedule Opportunity",
                "Medical, Dental, and Vision Insurance",
              ]}
              sx={{ m: 0 }}
            />
          </Box>
        </Box>
      </Container>
      <IndustryNewbie />
      <CultureTab />
      <Testimonials testimonials={testimonials} />
      <SupportInternal message="No matter the logistics problem, we are confident our experienced team will find the solution. We are here to support you 24/7/365." />
    </>
  );
}
