import React from "react";
import { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Divider,
  Collapse,
  Button,
} from "@mui/material";
import RPMmyCard from "../components/RPMmyCard/RPMmyCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const cards = [
  {
    name: "Brad Janer",
    title: "Best Leadership",
    image: "/eu-assets/rpmmy-1.webp",
    details:
      "Best Leader Award goes to the person who supports, guides, and works diligently for their team to be successful!",
  },
  {
    name: "Jenn Emard",
    title: "RPMer of the Year",
    image: "/eu-assets/rpmmy-2.webp",
    details:
      "The RPMer Of the Year Award goes to that someone who personifies all of RPM's core behaviors!",
  },
  {
    name: "Mason Trimble",
    title: "Best Under Pressure",
    image: "/eu-assets/rpmmy-3.webp",
    details:
      "Best Under Pressure Award goes to the person who remains calm and solid when faced with challenges.",
  },
  {
    name: "Kristina Youssef",
    title: "Most Helpful",
    image: "/eu-assets/rpmmy-4.webp",
    details:
      "Most Helpful Award goes to the person who is always willing to lend a hand and offer support to anyone who needs it!",
  },
  {
    name: "Brad Goldman",
    title: "Office Clown",
    image: "/eu-assets/rpmmy-5.webp",
    details:
      "The Office Clown Award goes to the person who plays hard and works harder! Someone who will break the ice in any situation!",
  },
  {
    name: "Kelley Holloway",
    title: "Silver Lining Award",
    image: "/eu-assets/rpmmy-6.webp",
    details:
      "The Silver Lining Award goes to the person who makes the world a little bit brighter, the most positive person around!",
  },
  {
    name: "Aaron Samouelian",
    title: "Broker of the Year",
    image: "/eu-assets/rpmmy-7.webp",
    details:
      "Broker Of the Year Award goes to the person who is committed to the customer and carrier experience, ensuring success in every meeting!",
  },
  {
    name: "Sean McCarthy",
    title: "Rookie of the Year",
    image: "/eu-assets/rpmmy-8.webp",
    details:
      "Rookie Of the Year Award goes to the new kid on the block who makes the biggest waves!",
  },
  {
    name: "Information Technology",
    title: "Best Team",
    image: "/eu-assets/rpmmy-9.webp",
    details:
      "The Best Team Award goes to the department that works as a connective thread to the whole company!",
  },
  {
    name: "Adam Jeanguenat",
    title: "Silent Assassin",
    image: "/eu-assets/rpmmy-10.webp",
    details:
      "The Silent Assassin Award goes to the person who stays in the background, taking out any hard job or problem the office faces, seen or not!",
  },
];

const RPMmys = () => {
  const [showMore, setShowMore] = useState(false);
  const handleChange = () => {
    setShowMore((prev) => !prev);
  };
  return (
    <Container sx={{ my: 4 }}>
      <Typography
        variant="h2"
        sx={{
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 9,
          textAlign: "center",
          mb: 2,
          textTransform: "none",
        }}
      >
        RPMmys
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{
          maxWidth: 868,
          textAlign: "center",
          margin: "auto",
          mb: 3,
        }}
      >
        The RPMmys categories, nominations, & winners are chosen anonymously by
        RPM employees. Allowing them to highlight their colleagues for their
        passion and service!
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {cards.slice(0, 5).map((card, i) => (
          <RPMmyCard
            key={i}
            name={card.name}
            title={card.title}
            img={card.image}
            details={card.details}
          />
        ))}
      </Box>
      {cards.length > 5 && (
        <>
          <Collapse in={showMore}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                my: 3,
                flexWrap: "wrap",
              }}
            >
              {cards.slice(5).map((card, i) => (
                <RPMmyCard
                  key={i}
                  name={card.name}
                  title={card.title}
                  img={card.image}
                  details={card.details}
                />
              ))}
            </Box>
          </Collapse>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 9,
            }}
          >
            <Divider sx={{ width: { xs: "90%", sm: "50%" } }}>
              <Button
                variant="text"
                onClick={handleChange}
                endIcon={
                  showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            </Divider>
          </Box>
        </>
      )}
    </Container>
  );
};

export default RPMmys;
