import { Box, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { HeadingTwo } from "../styles/UtilityStyles";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import ArticleCard from "../components/ArticleCard/ArticleCard";

const Blog = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [result, setResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const topOfPage = document.getElementsByTagName("body")[0];

  const handlePageClick = (event) => {
    topOfPage.scrollIntoView({ behavior: "smooth" });
    setItems([]);
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    const URL = `https://admin.rpmmoves.com/api/eu/posts?page=${currentPage}`;
    const handleFetch = () => {
      fetch(URL)
        .then((response) => response.json())
        .then((body) => {
          setResult(body[0]);
          setItems([...body[0].data]);
          setIsLoaded(true);
        })
        .catch((error) => console.error("Error", error));
    };
    handleFetch();
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>RPM Blog | The latest thought leadership from RPM</title>
        <meta
          name="description"
          content="Get the latest industry insights from RPM right here."
        />
        <meta property="og:title" content="RPM Blog | The latest thought leadership from RPM" />
        <meta
          property="og:description"
          content="Get the latest industry insights from RPM right here."
        />
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ py: 8, position: "relative", textAlign: "center" }}>
          <HeadingTwo>RPM Blog</HeadingTwo>
          <Typography sx={{ py: 2 }}>
            Our current and forward-thinking thoughts
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            pb: 3,
            justifyContent: "space-evenly",
            alignItems: "flex-start",
          }}
        >
          {isLoaded && items.length ? (
            items.map((item) => {
              return (
                <ArticleCard
                  key={item.id}
                  title={item.title}
                  publishedDate={item.published_date}
                  image={item.firstPhoto}
                  slug={item.slug}
                  excerpt={item.excerpt}
                  type="eu/blog"
                />
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "45vh",
                margin: "auto",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          )}
        </Box>
        {isLoaded ? (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
            <ReactPaginate
              pageCount={result.last_page}
              pageRangeDisplayed={2}
              previousLabel=""
              nextLabel=""
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"paginator"}
              previousLinkClassName={"page"}
              breakClassName={"page"}
              nextLinkClassName={"page"}
              pageClassName={"page"}
              disabledClassNae={"disabled"}
              activeClassName={"active"}
            />
          </Box>
        ) : (
          <div></div>
        )}
      </Container>
    </>
  );
};

export default Blog;
