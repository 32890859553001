import * as React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Typography, Button } from "@mui/material";

const CareersTab = () => {
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Grid
        container
        maxWidth="xl"
        sx={{ height: { xs: "auto", lg: "520px", margin: "auto" } }}
      >
        <Grid item xs={12} lg={4} sx={{ px: 3 }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ mt: { xs: 7, lg: 9 } }}
          >
            Careers
          </Typography>
          <Typography
            variant="h2"
            component="p"
            sx={{
              fontStyle: "italic",
              fontWeight: 700,
              mt: 4,
            }}
          >
            Work with us
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ py: 4, maxWidth: "424px" }}
          >
            We place the utmost importance on fostering a culture that treats
            employees with respect, offers work/life balance, and encourages
            personal growth.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              href="/eu/careers"
              aria-label="Click to view open positions"
              disableElevation
            >
              View Open Positions
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            display: { xs: "flex", lg: "flex" },
            position: "relative",
            mt: { xs: "65px", lg: 0 },
            mb: { xs: "56px", lg: 0 },
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: { xs: "475px", lg: 1062 },
              position: { xs: "relative", lg: "absolute" },
              right: 0,
            }}
            alt="Careers"
            src="/eu-assets/careers-lg.webp"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareersTab;
