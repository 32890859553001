import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const SolutionHeadline = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto Condensed",
  fontSize: "1.75rem",
  lineHeight: "2rem",
  textTransform: "uppercase",
  fontStyle: "italic",
  fontWeight: 700,
  margin: "0 0 16px",
}));

const VehicleSolutionCard = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid #C4C4C4",
        width: { xs: "auto", sm: "424px" },
        height: { xs: "auto", md: "224px" },
        margin: { xs: "8px", md: "16px 8px" },
      }}
    >
      {props.icon && (
        <Box
          sx={{
            width: "120px",
            height: "120px",
            background: "#EBEBEB",
            display: { xs: "none", md: "block" },
            mr: 2,
          }}
        ></Box>
      )}
      <Box sx={{ width: { xs: "100%", md: "288px" } }}>
        <SolutionHeadline>{props.headline}</SolutionHeadline>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {props.details}
        </Typography>
        {props.linkUrl && props.linkText && (
          <Button
            href={props.linkUrl}
            target="_blank"
            variant="text"
            endIcon={<ChevronRightRoundedIcon />}
            disableElevation
            sx={{
              pl: 0,
              mt: 0,
              height: "24px",
              color: "neutral.grey400",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            {props.linkText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default VehicleSolutionCard;
