import {
  Box,
  Container,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import BulletList from "../components/BulletList/BulletList";
import { HeadingTwo, HeadingFour } from "../styles/UtilityStyles";
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | RPM</title>
        <meta name="description" content="RPM Freight Systems LLC operates the www.RPMmoves.com website, which provides the SERVICE. This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the RPM website." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="md">
        <Box sx={{ py: 8 }}>
          <HeadingTwo>Privacy Policy of RPM Freight Systems LLC</HeadingTwo>
          <br />
          <Typography variant="body1">
            RPM Freight Systems LLC operates the www.RPMmoves.com website, which
            provides the SERVICE.
            <br />
            <br />
            This page is used to inform website visitors regarding our policies
            with the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service, the RPM website.
            <br />
            <br />
            If you choose to use our Service, then you agree to the collection
            and use of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
            <br />
            <br />
            The terms used in this Privacy Policy have the same meanings as in
            our&nbsp;
            <Link href="terms" underline="hover" sx={{ fontWeight: 500 }}>
              Terms and Conditions
            </Link>
            , which is accessible at www.RPMmoves.com, unless otherwise defined
            in this Privacy Policy. Our Privacy Policy was created with the help
            of the Privacy Policy Template and the Disclaimer Template.
          </Typography>

          <HeadingFour>Information Collection and Use</HeadingFour>
          <Typography variant="body1">
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and postal
            address. The information that we collect will be used to contact or
            identify you.
          </Typography>

          <HeadingFour>Log Data</HeadingFour>
          <Typography variant="body1">
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us that is called Log
            Data. This Log Data may include information such as your computer's
            Internet Protocol ("IP") address, browser version, pages of our
            Service that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
            <br />
            <br />
            While you have an active shipment with us, Geolocation data and
            route data may be automatically collected from registered devices.
            These devices may include a Driver App, onboard Electronic Logging
            Devices (ELDs) as well as other systems approved by the carrier.
            This data is used for the tracking of active shipments as well as
            automated pickup and delivery notifications of the shipments. We
            will automatically collect this data even when the app is closed for
            these purposes. When a driver or carrier does not have an active
            shipment with us, location data is not tracked.
          </Typography>

          <HeadingFour>Cookies</HeadingFour>
          <Typography variant="body1">
            Cookies are files with small amount of data that is commonly used an
            anonymous unique identifier. These are sent to your browser from the
            website that you visit and are stored on your computer's hard drive.
            <br />
            <br />
            Our website uses these "cookies" to collection information and to
            improve our Service. You have the option to either accept or refuse
            these cookies, and know when a cookie is being sent to your
            computer. If you choose to refuse our cookies, you may not be able
            to use some portions of our Service.
          </Typography>

          <HeadingFour>Service Providers</HeadingFour>
          <Typography variant="body1">
            We may employ third-party companies and individuals due to the
            following reasons:
          </Typography>

          <BulletList
            items={[
              "To facilitate our Service;",
              "To provide the Service on our behalf;",
              "To perform Service-related services; or",
              "To assist us in analyzing how our Service is used.",
            ]}
          />

          <Typography variant="body1">
            We want to inform our Service users that these third parties have
            access to your Personal Information. The reason is to perform the
            tasks assigned to them on our behalf. However, they are obligated
            not to disclose or use the information for any other purpose.
          </Typography>

          <HeadingFour>Security</HeadingFour>
          <Typography variant="body1">
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </Typography>

          <HeadingFour>Links to Other Sites</HeadingFour>
          <Typography variant="body1">
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </Typography>

          <HeadingFour>General Data Protection Regulation ("GDPR")</HeadingFour>
          <Typography variant="body1">
            GDPR requires RPM to communicate to individuals additional
            information about howwe process their personal information.
            <br />
            <br />
            Residents of the European Union. If you are resident in the European
            Union, in accordance with European Union privacy law, you have the
            following rights in respect of your personal information that we
            hold:
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              pl: 3,
              my: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
              <ListItemText variant="body1">
                Right of access. You have the right to obtain:
              </ListItemText>
            </ListItem>
            <List
              sx={{
                listStyleType: "circle",
                pl: 3,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
                <ListItemText variant="body1">
                  confirmation of whether, and where, we are processing your
                  personal information;
                </ListItemText>
              </ListItem>
              <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
                <ListItemText variant="body1">
                  information about the categories of personal information we
                  are processing, the purposes for which we process your
                  personal information and information as to how we determine
                  applicable retention periods;
                </ListItemText>
              </ListItem>
              <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
                <ListItemText variant="body1">
                  information about the categories of recipients with whom we
                  may share your personal information; and
                </ListItemText>
              </ListItem>
              <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
                <ListItemText variant="body1">
                  a copy of the personal information we hold about you.
                </ListItemText>
              </ListItem>
            </List>
            <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
              <ListItemText variant="body1">
                Right of portability. You have the right, in certain
                circumstances, to receive a copy of the personal information you
                have provided to us in a structured, commonly used,
                machine-readable format that supports re-use, or to request the
                transfer of your personal data to another person.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
              <ListItemText variant="body1">
                Right to rectification. You have the right to obtain
                rectification of any inaccurate or incomplete personal
                information we hold about you without undue delay.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
              <ListItemText variant="body1">
                Right to erasure. You have the right, in some circumstances, to
                require us to erase your personal information without undue
                delay if the continued processing of that personal information
                is not justified.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
              <ListItemText variant="body1">
                Right to restriction. You have the right, in some circumstances,
                to require us to limit the purposes for which we process your
                personal information if the continued processing of the personal
                information in this way is not justified, such as where the
                accuracy of the personal information is contested by you.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, fontFamily: "roboto" }}>
              <ListItemText variant="body1">
                Right to object. You have a right to object to any processing
                based on our legitimate interests where there are grounds
                relating to your particular situation. There may be compelling
                reasons for continuing to process your personal information, and
                we will assess and inform you if that is the case. You can
                object to marketing activities for any reason.
              </ListItemText>
            </ListItem>
          </List>

          <HeadingFour>Children's Privacy</HeadingFour>
          <Typography variant="body1">
            Our Services do not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </Typography>

          <HeadingFour>Changes to This Privacy Policy</HeadingFour>
          <Typography variant="body1">
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </Typography>

          <HeadingFour>Contact Us</HeadingFour>
          <Typography variant="body1">
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
