import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Container, Typography, Button } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box sx={{ py: 2 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerCarrierTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Ship and haul with us"
          centered
        >
          <Tab label="Customer" {...a11yProps(0)} />
          <Tab label="Carriers" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container maxWidth="xl" sx={{ pt: 5 }}>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                mt: 4,
              }}
            >
              Become a customer
            </Typography>
            <Typography variant="body1" component="p" sx={{ py: 4 }}>
              RPM delivers consistent shipments for customers, finding capacity
              wherever needed across Europe. Through a diverse carrier network,
              we ensure sustainable practices and efficient shipping methods.
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" href="/eu/customers" disableElevation>
                Ship With Us
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src="/eu-assets/customer-eu-5.webp"
              sx={{
                width: "100%",
                maxWidth: 864,
                height: "auto",
                mt: { xs: 6, md: 0 },
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container maxWidth="xl" sx={{ pt: 5 }}>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h2"
              component="p"
              sx={{
                fontStyle: "italic",
                fontWeight: 700,
                mt: 4,
              }}
            >
              Join our network
            </Typography>
            <Typography variant="body1" component="p" sx={{ py: 4 }}>
              We pride ourselves on offering carriers expert customer service,
              as well as better efficiencies and processes to keep them on the
              road longer and get paid faster.
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" href="/eu/carriers" disableElevation>
                Join Our Network
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src="/eu-assets/carrier-partner-eu-5.webp"
              sx={{
                width: "100%",
                maxWidth: 864,
                height: "auto",
                mt: { xs: 6, md: 0 },
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </Container>
  );
};

export default CustomerCarrierTabs;
