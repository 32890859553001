import React from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Fade from "react-reveal/Fade";
import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-material-ui-carousel";

const HeroBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "auto",
    padding: "0",
    backgroundImage: "none",
  },
}));

const HeroBoxTop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "345px",
  },
}));

const HeroBoxBottom = styled(Box)(({ theme }) => ({
  padding: "64px 0",
  maxWidth: "380px",
  [theme.breakpoints.down("md")]: {
    backgroundImage: `url(${"/eu-assets/home-hero-text-mb.webp"})`,
    backgroundSize: "cover",
    maxWidth: "100%",
    padding: "56px 25px",
  },
}));

const ScrollDownButton = styled(Button)(({ theme }) => ({
  width: 96,
  height: 96,
  backgroundImage: `url(${"/eu-assets/scroll-down.webp"})`,
  backgroundColor: "transparent",
  backgroundSize: 96,
  position: "absolute",
  bottom: "-20px",
  left: "calc(50% - 48px)",
  borderRadius: "48px",
  padding: 0,
  zIndex: 1,
  [theme.breakpoints.down("md")]: {
    width: 48,
    height: 48,
    backgroundSize: 48,
    minWidth: 48,
    left: "calc(50% - 24px)",
    bottom: "-24px",
  },
}));

const heroItems = ["/eu-assets/home-hero-slide-9.webp"];

const HomeHero = (props) => {
  const handleClick = () => {
    document
      .getElementById("trusted-companies")
      .scrollIntoView({ behavior: "smooth" });
  };
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          height: 570,
          backgroundImage: `url(${"/eu-assets/home-hero-sail.webp"})`,
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
          position: "relative",
          backgroundPosition: "50% 0",
          overflowX: "hidden",
          [theme.breakpoints.down("md")]: {
            height: "auto",
            padding: "0",
            backgroundImage: "none",
          },
        }}
      >
        <Carousel
          swipe={false}
          indicators={false}
          duration={2000}
          interval={6000}
          sx={{
            position: "absolute",
            zIndex: "-1",
            right: "50%",
            transform: "translateX(50%)",
            height: 570,
            width: 2110,
            top: 0,
            [theme.breakpoints.down("md")]: {
              top: "-25px",
              right: "40%",
              height: "375px",
            },
          }}
        >
          {heroItems.map((item, i) => (
            <Box
              component="img"
              src={item}
              alt={"Hero image " + i}
              key={i}
              sx={{
                height: 570,
                [theme.breakpoints.down("md")]: {
                  height: 390,
                },
              }}
            />
          ))}
        </Carousel>
        <Container disableGutters={useMediaQuery(theme.breakpoints.down("md"))}>
          <HeroBox>
            <HeroBoxTop></HeroBoxTop>
            <HeroBoxBottom>
              <Fade left>
                <Typography
                  variant="h1"
                  sx={{
                    color: "#fff",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                  }}
                >
                  {props.tagline}
                </Typography>
              </Fade>
              <Fade bottom>
                <Typography
                  variant="body1"
                  component="p"
                  color="white"
                  sx={{
                    py: 3,
                  }}
                >
                  {props.copy}
                </Typography>
              </Fade>
              <Button variant="outlined" href="/eu/contact">
                Let’s Talk
              </Button>
            </HeroBoxBottom>
          </HeroBox>
        </Container>
      </Box>
      <ScrollDownButton
        onClick={handleClick}
        aria-label="Scroll down the page"
      />
    </Box>
  );
};

export default HomeHero;
