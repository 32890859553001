import React from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import RPMLogo from "../logo.svg";
import { Container } from "@mui/system";
import SocialLinkedIn from "../icons/SocialLinkedIn.svg";
import SocialInstagram from "../icons/SocialInstagram.svg";
import SocialFacebook from "../icons/SocialFacebook.svg";
import SocialYouTube from "../icons/SocialYouTube.svg";
import FooterLink from "../components/FooterLink/FooterLink";
import SocialLink from "../components/SocialLink/SocialLink";

const SiteFooter = () => {
  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", lg: "flex-start" },
          color: "neutral.grey400",
          py: 5,
          borderTop: { xs: "1px solid #3C3C3C", lg: "none" },
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box sx={{ display: "flex", paddingRight: { xs: 0, lg: "100px" } }}>
          <img src={RPMLogo} alt="RPM Logo" width="237" height="60" />
        </Box>
        <Box
          sx={{ pt: 1, width: "100%", textAlign: { xs: "center", lg: "left" } }}
        >
          <Typography sx={{ fontWeight: 700 }}>RPM Europe B.V.</Typography>
          <Typography variant="body2">
            Keizersgracht 555
            <br />
            Floor 3<br />
            1017DR Amsterdam
            <br />
            The Netherlands
          </Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            +31 20 299 8039
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", lg: "flex-start" },
            }}
          >
            <SocialLink
              url="https://www.linkedin.com/company/rpmmoves"
              img={SocialLinkedIn}
              label="LinkedIn"
            />
            <SocialLink
              url="https://www.instagram.com/rpmmoves/"
              img={SocialInstagram}
              label="Instagram"
            />
            <SocialLink
              url="https://www.facebook.com/RPMmoves/"
              img={SocialFacebook}
              label="Facebook"
            />
            <SocialLink
              url="https://www.youtube.com/channel/UC7mb373srmXHc1URe-iULDw"
              img={SocialYouTube}
              label="YouTube"
            />
          </Box>
        </Box>
        <Box sx={{ pt: { xs: 6, lg: 1 }, width: "100%" }}>
          <Typography component="p" variant="body3">
            Company
          </Typography>
          <FooterLink url="/eu/about-us" label="About Us" />
          <FooterLink url="/eu/culture" label="Culture" />
          <FooterLink url="/eu/contact" label="Contact us" />
          <FooterLink url="https://myrpm.rpmmoves.com" label="Employee Login" />
        </Box>
        <Box sx={{ pt: { xs: 6, lg: 1 }, width: "100%" }}>
          <Typography component="p" variant="body3">
            Services
          </Typography>
          <FooterLink url="/eu/customers" label="Customers" />
          <FooterLink url="/eu/carriers" label="Carriers" />
          <FooterLink url="/eu/technology" label="Technology" />
        </Box>

        <Box sx={{ pt: { xs: 6, lg: 1 }, width: "100%" }}>
          <Typography component="p" variant="body3">
            Locations
          </Typography>
          <FooterLink url="https://www.rpmmoves.com" label="North America" />
          <FooterLink url="https://www.rpmmoves.com/mx" label="Mexico" />
          <FooterLink url="https://www.rpmmoves.com/eu" label="Europe" />
        </Box>
      </Container>
      <Container>
        <Divider sx={{ width: "100%" }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", lg: "row" },
            pt: 5,
            pb: 6,
          }}
        >
          <Box>
            <Link
              href="/eu/general-terms"
              underline="hover"
              color="neutral.grey400"
              sx={{
                my: 1,
                display: "inline-flex",
                justifyContent: "center",
                width: { xs: "50%", lg: "auto" },
              }}
            >
              <Typography variant="body4">General Terms</Typography>
            </Link>
            <Link
              href="/eu-assets/Carrier_Terms_and_conditions_RPM_Europe_B.V._v1.2.pdf"
              underline="hover"
              color="neutral.grey400"
              target="_blank"
              sx={{
                my: 1,
                display: "inline-flex",
                justifyContent: "center",
                width: { xs: "50%", lg: "auto" },
                ml: { xs: 0, lg: "32px" },
              }}
            >
              <Typography variant="body4">Carrier Agreement</Typography>
            </Link>
            <Link
              href="/eu/terms"
              underline="hover"
              color="neutral.grey400"
              sx={{
                my: 1,
                display: "inline-flex",
                justifyContent: "center",
                width: { xs: "50%", lg: "auto" },
                ml: { xs: 0, lg: "32px" },
              }}
            >
              <Typography variant="body4">Terms & Conditions</Typography>
            </Link>
            <Link
              href="/eu/privacy"
              underline="hover"
              color="neutral.grey400"
              sx={{
                my: 1,
                display: "inline-flex",
                justifyContent: "center",
                width: { xs: "50%", lg: "auto" },
                ml: { xs: 0, lg: "32px" },
              }}
            >
              <Typography variant="body4">Privacy Policy</Typography>
            </Link>
          </Box>
          <Box>
            <Typography
              variant="body4"
              color="neutral.grey400"
              sx={{ p: 4, display: "flex" }}
            >
              &copy; 2022 RPM Freight Systems. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SiteFooter;
