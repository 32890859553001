import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { HeadingTwo, HeadingFour } from "../styles/UtilityStyles";

const GeneralTerms = () => {
  return (
    <>
      <Helmet>
        <title>RPM | General Terms & Conditions</title>
        <meta name="description" content="Terms & Conditions" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="md">
        <Box sx={{ py: 8 }}>
          <HeadingTwo>RPM General Terms & Conditions</HeadingTwo>
          <HeadingFour>Definitions</HeadingFour>
          <Typography variant="body1">
            <strong>Carrier:</strong> the natural person or legal entity who is
            contracted by RPM and who undertakes to transport the Goods.
            <br />
            <br />
            <strong>General Terms & Conditions:</strong> these general terms and
            conditions.
            <br />
            <br />
            <strong>Goods:</strong> the cargo/commodities from the SHIPPER that
            are the subject of the Services and includes the unit used to
            consolidate and transport the Goods.
            <br />
            <br />
            <strong>RPM:</strong> RPM Europe B.V.
            <br />
            <br />
            <strong>Services:</strong> all services provided by RPM, including
            the arrangement of transportation of Goods pursuant to these General
            Terms & Conditions.
            <br />
            <br />
            <strong>SHIPPER:</strong> the natural person or legal entity,
            including the owner, sender, receiver and their agents,
            subcontractors, or any other person or entity and their agents, who
            has an interest in the Goods in respect of which Services are
            carried out or provided by RPM.
          </Typography>

          <HeadingFour>APPLICABILITY</HeadingFour>
          <Typography variant="body1">
            These General Terms & Conditions apply to all quotations, offers,
            booking confirmations and contracts made by RPM and to all legal and
            factual acts performed in that connection. The applicability of the
            general terms and conditions used by SHIPPER, if any, is explicitly
            rejected by RPM. If any (part of a) provision of these General Terms
            & Conditions is void or voidable, such shall not affect the validity
            of the other (parts of the) provisions. In the event of
            inconsistency between these General Terms & Conditions and the
            quotation and/or booking confirmation or any other communication or
            contract, these General Terms & Conditions shall prevail except in
            respect of rates and other charges, in which case the quotation
            and/or booking confirmation shall prevail.
          </Typography>

          <HeadingFour>Services</HeadingFour>
          <Typography variant="body1">
            RPM agrees to provide the Services pursuant to these General Terms &
            Conditions and in compliance with all applicable laws and
            regulations relating thereto. RPM’s responsibility shall be limited
            to arranging for, but not actually performing, transportation of
            Goods. These General Terms & Conditions shall apply to all shipments
            and orders from SHIPPER. During the performance of the Services, RPM
            acts as a freight forwarder (in Dutch: ‘expediteur’) within the
            meaning of Article 8:60 of the Dutch Civil Code, and never as a
            carrier, neither contractual nor factual carrier (in Dutch:
            ‘papieren of feitelijke vervoerder’). These General Terms &
            Conditions shall only apply to shipments within Europe.
          </Typography>

          <HeadingFour>Shipper’s obligations</HeadingFour>
          <Typography variant="body1">
            SHIPPER shall be responsible to RPM for timely and accurate delivery
            instructions and description of the Goods, including any special
            handling requirements, for any shipment. SHIPPER guarantees that the
            information and documents provided are correct and complete and that
            all instructions comply with applicable rules and legislation,
            including – but not limited to – privacy and data protection laws
            and regulations, laws on hazardous goods and weight/overloading
            restrictions. RPM shall not be obliged to investigate whether the
            information or documents provided are correct and complete. Times
            for delivery or arrival times stated by RPM are not guaranteed by
            RPM.
          </Typography>
          <HeadingFour>Carriers</HeadingFour>
          <Typography variant="body1">
            RPM warrants that it has entered into, or will enter into, bilateral
            contracts with each Carrier it utilizes for the provision of the
            Services. RPM further warrants that those contracts comply with all
            applicable laws and regulations and shall include the following
            provisions:
          </Typography>
          <Box sx={{ pl: 2.25 }}>
            <ol type="A">
              <li>
                <Typography variant="body1">
                  Carrier shall agree to defend, indemnify and hold RPM and
                  SHIPPER harmless from all damages, claims or losses arising
                  out of its performance of the transportation services,
                  including cargo loss and damage, theft, delay, damage to
                  property, and personal injury or death, in so far as Carrier’s
                  liability is not excluded or limited by any mandatorily
                  applicable laws, regulations or conventions, including – but
                  not limited to – the Convention on the Contract for the
                  International Carriage of Goods by Road, signed in Geneva on
                  19 May 1956, as amended by the Protocol to the Convention on
                  the Contract for the International Carriage of Products by
                  Road, singed in Geneva on 5 July 1978 (CMR Convention).
                  Exclusions in Carrier’s insurance coverage shall not exonerate
                  Carrier from this liability.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Carrier shall agree to maintain at all times during the term
                  of the contract, adequate liability insurance against all
                  risks which can be reasonably expected in the performance of
                  the transportation services, such as loss or damage to the
                  Goods during carriage (cargo damage), liability for damage
                  caused to property of RPM and/or SHIPPER and/or third parties
                  (property damage), third party motor vehicle liability as well
                  as liability for bodily injury damage and damage upon the
                  death of employees engaged in the performance of the
                  transportation services.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Carrier shall authorize RPM to invoice SHIPPER for
                  transportation services provided by the Carrier. Carrier shall
                  further agree that RPM is the sole party responsible for
                  payment of its invoices and that, under no circumstance, will
                  Carrier seek payment from the SHIPPER or consignee.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Carrier shall agree that the terms and conditions of its
                  contract with RPM shall apply to all shipments it handles for
                  RPM.
                </Typography>
              </li>
            </ol>
          </Box>
          <HeadingFour>Receipts and transport documents</HeadingFour>
          <Typography variant="body1">
            If requested by SHIPPER, RPM agrees to provide SHIPPER with proof of
            acceptance and delivery of such loads in the form of a signed CMR
            note, consignment note, waybill, (“Transport Document”), Inspection
            Form or Proof of Delivery, as specified by SHIPPER. SHIPPER’s
            insertion of RPM’s name on a Transport Document shall be for SHIPPER
            convenience only and shall not change RPM’s status as a freight
            forwarder. The terms and conditions of any Transport Document used
            by RPM or Carrier may not supplement, alter, or modify these General
            Terms & Conditions.
          </Typography>
          <HeadingFour>Payments</HeadingFour>
          <Typography variant="body1">
            RPM shall invoice SHIPPER for its Services in accordance with the
            rates, charges and provisions that are mutually agreed to between
            RPM and SHIPPER. If rates are negotiated between RPM and SHIPPER and
            not otherwise confirmed in writing, such rates shall be considered
            “written,” and shall be binding, upon RPM’s invoice to SHIPPER and
            SHIPPER’s payment to RPM. SHIPPER agrees to pay RPM’s invoice within
            30 days of invoice date without deduction, suspension or setoff. The
            invoice shall also be due if damage, loss or delay has occurred
            during the transportation of Goods. RPM shall apply payment to the
            amount due for the specified invoice, regardless whether there are
            earlier unpaid invoices. Payment of any cargo charges to RPM shall
            relieve SHIPPER, consignee or other responsible party of any
            liability to the Carrier for non-payment of said cargo charges; and
            RPM hereby covenants and agrees to indemnify SHIPPER, consignee or
            other responsible party against such liability.
          </Typography>
          <HeadingFour>Claims</HeadingFour>
          <Box sx={{ pl: 2.25 }}>
            <ol type="A">
              <li>
                <Typography variant="body1">
                  <strong>Cargo Claims:</strong> SHIPPER must notify RPM within
                  seven (7) days of any claims for cargo loss or damage or delay
                  from the date of such loss, shortage or damage, which shall be
                  the delivery date or, in the event of non-delivery, the
                  scheduled delivery date. SHIPPER must file claims for cargo
                  loss or damage with RPM within ninety days (90) days from the
                  date of notice. SHIPPER must file any civil action against RPM
                  within one (1) year from the date the Carrier or RPM provides
                  written notice to SHIPPER that the Carrier has disallowed any
                  part of the claim in the notice. RPM shall make all reasonable
                  efforts to recover the SHIPPER’s damage or loss from the party
                  that is liable for such damage or loss. RPM shall be entitled
                  to charge to the SHIPPER the costs incidental thereto.
                  Carriers shall agree in writing with RPM to be liable for
                  cargo loss or damage or delay as outlined in paragraph 5
                  above. It is understood and agreed that RPM is not a Carrier
                  and that RPM shall not be held liable for loss, damage or
                  delay in the transportation of Goods unless caused by the
                  willful intent or gross negligence of RPM or its management in
                  the performance of the Services; provided that,
                  notwithstanding anything in these General Terms & Conditions
                  to the contrary, RPM’s aggregate liability for any one
                  shipment shall not exceed EUR 200,000 and RPM’s annual
                  aggregate liability shall not exceed EUR 500,000. RPM shall
                  manage the filing, processing, and resolution of claims with
                  the Carrier on behalf of SHIPPER. Without prejudice to the
                  scope of RPM’s liability, if payment of claim is made by RPM
                  to SHIPPER, SHIPPER automatically assigns its rights and
                  interests in the claim to RPM to allow RPM to subrogate its
                  loss. In no event shall RPM or Carrier be liable to SHIPPER or
                  anyone else for special, incidental, indirect, punitive or
                  consequential damages.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <strong>All Other Claims:</strong> RPM and SHIPPER shall
                  notify each other within sixty (60) days of learning of any
                  claims other than cargo loss or damage or delay claims and
                  shall file any such claims with the other party within one
                  hundred eighty (180) days from the date of notice. Civil
                  action, if any, shall be commenced within two (2) years from
                  the date either party provides written notice to the other
                  party of such a claim. SHIPPER shall be liable for any damage,
                  loss, costs or whatsoever suffered by RPM that is a result of
                  or has any relation with the Services. RPM shall not be liable
                  for any damage, loss, costs or whatsoever, unless SHIPPER
                  proves that the damage has been caused by the willful intent
                  or gross negligence of RPM or its management. In any event,
                  RPM’s liability shall in all cases be limited to EUR 50,000
                  per occurrence or series of occurrences with one and the same
                  cause of damage, with a maximum annual aggregate liability of
                  EUR 100,000.
                </Typography>
              </li>
            </ol>
          </Box>
          <HeadingFour>Insurance</HeadingFour>
          <Typography variant="body1">
            RPM agrees to procure and maintain at its own expense an adequate
            general liability insurance covering RPM’s liability under these
            General Terms & Conditions. RPM shall not be obliged to procure or
            maintain any other insurance, such as a cargo or property damage
            insurance, unless SHIPPER explicitly instructed RPM to take out such
            insurance and such insurance is fully at SHIPPER’s expense and risk.
          </Typography>
          <HeadingFour>Hazardous Goods</HeadingFour>
          <Typography variant="body1">
            SHIPPER and RPM shall comply with all applicable laws and
            regulations relating to the transportation of hazardous goods,
            including – but not limited to – the European Agreement concerning
            the International Carriage of Dangerous Products by Road (ADR) to
            the extent that any shipments constitute hazardous goods. SHIPPER is
            obligated to inform RPM immediately if any such shipments do
            constitute hazardous goods. SHIPPER shall defend, indemnify and hold
            RPM harmless from any penalties or liability of any kind, including
            reasonable attorney fees, arising out of SHIPPER’s failure to comply
            with applicable hazardous goods laws and regulations.
          </Typography>
          <HeadingFour>INDEMNIFICATION</HeadingFour>
          <Typography variant="body1">
            SHIPPER shall indemnify, hold harmless and defend RPM (including its
            directors, employees and other parties engaged by RPM) from and
            against all liabilities, losses, costs, damage and expenses
            (including all legal fees and court fees) incurred or sustained by
            reason of or arising from the non-compliance by the SHIPPER, its
            directors, employees, or any other party acting on instruction from
            or on behalf of SHIPPER, of any obligations pursuant to these
            General Terms & Conditions or pursuant to applicable national and/or
            international laws or regulations. SHIPPER shall at all times be
            obliged to indemnify, hold harmless and defend RPM for any amounts
            to be levied or demanded by any authority and/or any fines or
            penalties imposed, in connection with the Services.
          </Typography>
          <HeadingFour>RIGHT OF RETENTION AND PLEDGE</HeadingFour>
          <Typography variant="body1">
            RPM has a right of retention in respect of all goods, including –
            but not limited to – the Goods, documents and monies that RPM holds
            or will hold for whatever reason and with whatever destination, for
            all claims RPM has or might have in future on SHIPPER and/or the
            owner of the goods, including in respect of all claims which do not
            relate to those goods, such as previous orders. RPM has a right of
            pledge (lien) in respect of all goods, including – but not limited
            to – the Goods, documents and monies that RPM holds or will hold for
            whatever reason and with whatever destination, for all claims RPM
            has or might have in future on the SHIPPER and/or the owner of the
            goods.
          </Typography>
          <HeadingFour>SECURITY</HeadingFour>
          <Typography variant="body1">
            At RPM’s first request, SHIPPER shall provide security for the
            amount owed or that shall be owed by SHIPPER to RPM. At RPM’s first
            request, SHIPPER shall provide security for costs paid or to be paid
            by RPM to third parties or authorities, including freight, port
            costs, duties, taxes, levies, fines and penalties.
          </Typography>
          <HeadingFour>INDEPENDENT CONTRACTOR</HeadingFour>
          <Typography variant="body1">
            SHIPPER shall remain at all times an independent contractor. SHIPPER
            does not exercise or retain any control or supervision over RPM, its
            operations, employees, or carriers. Nothing in these General Terms &
            Conditions create any partnership, joint venture, employment, agency
            or fiduciary relationship between RPM and SHIPPER.
          </Typography>
          <HeadingFour>FORCE MAJEURE</HeadingFour>
          <Typography variant="body1">
            RPM nor SHIPPER shall be liable to the other for failure to perform
            any of its obligations during any time in which such performance is
            prevented by fire, flood, or other natural disaster, war, embargo,
            riot, civil disobedience, the intervention of any government
            authority, cyberattacks, cybercrime, any force majeure event on
            which the Carrier may rely or any other cause outside of the
            reasonable control of the SHIPPER or RPM, provided that the party so
            prevented uses its best efforts to perform and provided further,
            that such party provide reasonable notice to the other party of such
            inability to perform. In the event of Force Majeure, RPM’s
            obligations shall be suspended for the duration of the Force Majeure
            event. All additional costs caused by Force Majeure, such as
            transport and storage charges, warehouse or yard rental, demurrage
            and standing fees, insurance, removal costs etcetera, shall be for
            the account of the SHIPPER and shall be paid to RPM at RPM’s first
            request.
          </Typography>
          <HeadingFour>CONFIDENTIALITY</HeadingFour>
          <Typography variant="body1">
            RPM shall not utilize SHIPPER’s name or identity in any advertising
            or promotional communications without written confirmation of
            SHIPPER’s consent and the RPM and SHIPPER shall not publish, use or
            disclose the contents or existence of their correspondence except as
            necessary to conduct their obligations under these General Terms &
            Conditions.
          </Typography>
          <HeadingFour>CHOICE OF LAW & JURISDICTION</HeadingFour>
          <Typography variant="body1">
            All questions concerning the construction, interpretation, validity
            and enforceability of these General Terms & Conditions and
            concerning the legal relationship between RPM and SHIPPER, whether
            in a court of law or in arbitration, shall be governed by and
            construed and enforced in accordance with the laws of the
            Netherlands. Any and all disputes between RPM and SHIPPER howsoever
            arising under, in relation to or in connection with the Services or
            these General Terms & Conditions, shall be brought exclusively
            before the competent Court in Rotterdam, the Netherlands.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default GeneralTerms;
