export const navItems = [
  {
    id: 1,
    title: "Customers",
    url: "/eu/customers",
  },
  {
    id: 2,
    title: "Carriers",
    url: "/eu/carriers",
  },
  {
    id: 3,
    title: "About",
    url: "/eu/about-us",
    submenu: [
      {
        title: "Careers",
        url: "/eu/careers",
      },
    ],
  },
  {
    id: 4,
    title: "Resources",
    submenu: [
      {
        title: "News",
        url: "/eu/news",
      },
      {
        title: "Blog",
        url: "/eu/blog",
      },
    ],
  },
  {
    id: 5,
    title: "Technology",
    url: "/eu/technology",
  },
];
